import React from 'react';

import Icon from './Icon';

interface ICaptionAndCreditProps {
  caption?: string;
  credit?: string;
}

const CaptionAndCredit: React.FC<ICaptionAndCreditProps> = ({
  caption,
  credit,
}) => (
  <figcaption className="c-caption has-text-gray-dark l-display-block t-size-xs t-links-underlined">
    {caption}{' '}
    {credit && (
      <cite>
        <Icon iconName="camera" display={{ baseline: true }} /> {credit}
      </cite>
    )}
  </figcaption>
);

export default CaptionAndCredit;
