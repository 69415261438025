import React from 'react';
import ContentLoader from 'react-content-loader';

interface StringObject {
  [key: string]: string;
}
interface IBigLoaderTextProps {
  height?: string;
  width?: string;
  style?: StringObject;
  className?: string;
}

const TextLoader: React.FC<IBigLoaderTextProps> = ({
  height = '16px',
  width = '100%',
  style,
  className,
}) => (
  <ContentLoader
    height={height}
    width={width}
    style={style}
    className={className}
    preserveAspectRatio="none"
    animate={false}
    backgroundColor="#d0d0d0"
    foregroundColor="#d0d0d0"
    title="Loading indicator"
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ContentLoader>
);

export default TextLoader;
