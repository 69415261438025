/**
 * Checks if the given array contains duplicates.
 * Courtesy of https://stackoverflow.com/a/54974076
 *
 * @param {Array} arr - array to check for duplicates
 *
 * @returns {Boolean} - true if duplicates exist, false otherwise
 */
function checkDuplicates(arr: Array<number | string>): boolean {
  return new Set(arr).size !== arr.length;
}

export default checkDuplicates;
