import React from 'react';
import TextLoader from '../../display/components/TextLoader';

const StoryBlockLoader: React.FC = () => (
  <li className="has-b-btm-marg">
    <article className="c-story-block c-story-block--read-more c-story-block--stack-until-bp-m">
      <header className="c-story-block__text">
        <TextLoader style={{ marginBottom: '7px' }} />
        <TextLoader style={{ marginBottom: '7px' }} />
        <TextLoader
          height="12px"
          width="100px"
          style={{ maxWidth: '100%' }}
          className="is-hidden-until-bp-m"
        />
      </header>
      <figure className="c-story-block__image-wrap is-hidden-until-bp-m">
        <div className="c-image__placeholder has-bg-gray-light" />
      </figure>
    </article>
  </li>
);

export default StoryBlockLoader;
