import React from 'react';
import { render } from 'react-dom';
import ArticleRecs from './components/ArticleRecs';
import select from '../utils/select';

interface IBuildArticleRecs {
  selectorSuffix: string;
  articleId: number;
  recCount?: number;
}

function buildArticleRecs({
  selectorSuffix,
  articleId,
  recCount = 10,
}: IBuildArticleRecs): void {
  const selector = `#article-recs-${selectorSuffix}`;
  const targetEl = select(selector);
  if (targetEl === null) {
    throw new Error(`Couldn't find element with selector ${selector}`);
  }
  const ToRender = (
    <ArticleRecs
      articleId={articleId}
      recCount={recCount}
      scrollTarget={targetEl}
    />
  );
  return render(ToRender, targetEl);
}

export default buildArticleRecs;
