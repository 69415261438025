export const SELECTOR = 'rug';

export enum ERugFeaturedType {
  UpcomingEvents = 'UpcomingEvents',
  LatestVideos = 'LatestVideos',
  Random = 'Random',
}

export interface IBuildRugParams {
  featuredType: ERugFeaturedType;
  ga: { label: string };
}
