import React from 'react';
import StoryBlock from './StoryBlock';
import { IApiArticle } from '../../types';
import useFetch from '../../api/hooks/use-fetch';
import fetchRecs from '../utils/fetchRecs';
import ArticleRecsLoader from './ArticleRecsLoader';
import Container from './Container';

interface IArticleRecsProps {
  scrollTarget: HTMLElement;
  articleId: number;
  recCount?: number;
}

const ArticleRecs: React.FC<IArticleRecsProps> = ({
  scrollTarget,
  articleId,
  recCount,
}) => {
  const { data, isEmpty, isPending, didError } = useFetch({
    element: scrollTarget,
    fetchData: () => fetchRecs(articleId, { recCount }),
    observerOptions: {
      rootMargin: '500px',
    },
  });

  if (didError || isEmpty) {
    return null;
  }

  if (isPending) {
    return <ArticleRecsLoader articles={recCount} />;
  }

  return (
    data && (
      <Container>
        {data.map(
          ({
            id,
            headline,
            ap_pub_date: pubDate,
            ap_updated_date: updatedDate,
            url,
            sitewide_image: sitewideImage,
          }: IApiArticle) => {
            return (
              <StoryBlock
                key={id}
                headline={headline}
                pubDate={pubDate}
                updatedDate={updatedDate}
                url={url}
                sitewideImage={sitewideImage}
              />
            );
          }
        )}
      </Container>
    )
  );
};

export default ArticleRecs;
