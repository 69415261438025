import bindScrollActions from '../utils/scroll-actions';
import select from '../utils/select';
import { SELECTOR, IBuildRugParams } from './constants';

export default function buildRugOnScroll(config: IBuildRugParams): void {
  const targetElement: HTMLElement = select(`#${SELECTOR}`);

  const callback = async (): Promise<void> => {
    const mod = await import(/* webpackChunkName: "build-rug", webpackPrefetch: true */ './index');
    mod.default(config);
  };

  bindScrollActions({
    callback,
    elements: [targetElement],
    doOnce: true,
    observerOptions: { rootMargin: `${window.innerHeight}px` },
  });
}
