import React from 'react';
import Image from '../../display/components/Image';
import formatImage from '../../format-image';
import TrackedLink from '../../display/components/TrackedLink';
import { IApiImage } from '../../types';
import DateDisplay from './DateDisplay';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../constants';

interface IStoryBlockProps {
  headline: string;
  pubDate: string;
  updatedDate?: string | null;
  url: string;
  sitewideImage: IApiImage;
}

const StoryBlock: React.FC<IStoryBlockProps> = ({
  headline,
  pubDate,
  updatedDate,
  url,
  sitewideImage,
}) => {
  const fmttedImage = formatImage(sitewideImage);

  return (
    <li className="has-b-btm-marg">
      <article className="c-story-block c-story-block--read-more c-story-block--stack-until-bp-m">
        <header className="c-story-block__text t-links-underlined-hover">
          <h3 className="has-tiny-btm-marg t-serif">
            <TrackedLink
              href={url}
              category={GA_CATEGORY}
              action={GA_ACTION}
              label={GA_LABEL}
            >
              {' '}
              <strong>{headline}</strong>
            </TrackedLink>
          </h3>
          <DateDisplay pubDate={pubDate} updatedDate={updatedDate} />
        </header>
        <figure className="c-story-block__image-wrap is-hidden-until-bp-m">
          <TrackedLink
            href={url}
            category={GA_CATEGORY}
            action={GA_ACTION}
            label={GA_LABEL}
          >
            <Image
              description="Thumbnail for story block"
              sources={fmttedImage.thumbs}
            />
          </TrackedLink>
        </figure>
      </article>
    </li>
  );
};

export default StoryBlock;
