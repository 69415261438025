import React from 'react';

interface IDateDisplayProps {
  pubDate: string;
  updatedDate?: string | null;
}

const DateDisplay: React.FC<IDateDisplayProps> = ({ pubDate, updatedDate }) => {
  return (
    <p className="t-byline t-uppercase t-lsp-b t-sans t-size-xs has-text-gray-dark is-hidden-until-bp-m">
      <time className="t-byline__item l-display-ib" dateTime={pubDate}>
        {updatedDate ? `Updated: ${updatedDate}` : pubDate}
      </time>
    </p>
  );
};

export default DateDisplay;
