import React from 'react';

interface IContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<IContainerProps> = ({ children }) => {
  return (
    <section className="t-serif">
      <div
        className="has-notch has-bg-yellow has-b-btm-marg"
        aria-hidden="true"
      />
      <h2 className="t-sans t-uppercase t-lsp-b t-size-b has-b-btm-marg has-text-black">
        Read More
      </h2>
      <ul className="has-l-btm-marg">{children}</ul>
    </section>
  );
};

export default Container;
