import { autoInitFrames } from '@newswire/frames';
import { fireEntryDispatches } from '../kickstart';
import buildSignUp from '../sign-up';
import buildSeriesLatest from '../series-latest/on-scroll';
import buildRug from '../rug/on-scroll';
import buildArticleRecs from '../article-recs';
// import { buildPromoModal } from '../promo';
import incrementCookie from '../promo/common/utils/cookie-increment';
import { shouldShowPromo } from '../promo/common/utils/cookie-actions';

fireEntryDispatches();
autoInitFrames();

async function buildAudio(config) {
  const module = await import(/* webpackChunkName: "build-audio" */ '../audio');
  module.default(config);
}

async function buildVideo(config) {
  const module = await import(/* webpackChunkName: "build-video" */ '../video');
  module.default(config);
}
// article tracking cookie
incrementCookie();

export * from '../kickstart';
export {
  buildAudio,
  buildVideo,
  buildSignUp,
  buildSeriesLatest,
  buildRug,
  buildArticleRecs,
  // buildPromoModal,
  shouldShowPromo,
};
