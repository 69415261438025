import { ERR_MSG_LNL_FETCH_FAILED } from '../constants';
import checkDuplicates from './checkDuplicates';
import buildLnlUrl from './buildLnlUrl';

interface ILnlResponseItem {
  /* eslint-disable camelcase */
  recommended_article: {
    external_id: number;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

/**
 * Fetches a list of IDs for recommended articles from Local News Lab API
 *
 * @param {Number} contentId - ID of article to fetch related articles for
 * @param {Number} recCount - Number of articles to fetch. If undefined, LNL API will return
 * 50 recommendations
 *
 * @returns {Promise<Array>} - Promise of array of IDs for recommended articles
 */
const fetchLnlPayload = async (
  contentId: number,
  recCount?: number
): Promise<number[]> => {
  const url = buildLnlUrl(contentId, { recCount });
  const res = await fetch(url);
  if (!res.ok) {
    const msg = `${ERR_MSG_LNL_FETCH_FAILED}: ${res.status}`;
    throw new Error(msg);
  }
  const payload = await res.json();
  if (!payload.results || !payload.results.length) {
    const msg = `${ERR_MSG_LNL_FETCH_FAILED}: no results returned`;
    throw new Error(msg);
  }
  // A response that includes duplicate IDs is considered unexpected behavior,
  // therefore we throw an error
  const targetIds = payload.results.map(
    (item: ILnlResponseItem) => item.recommended_article.external_id
  );
  if (checkDuplicates(targetIds)) {
    const msg = `${ERR_MSG_LNL_FETCH_FAILED}: duplicate IDs returned`;
    throw new Error(msg);
  }
  // Note: A bug in Local News Lab's API may cause it to return more results
  // than set by the size param. We check to ensure our list of target IDs
  // doesn't exceed the limit set by the size param.
  const safeLimit = recCount || payload.results.length;
  const limitTargetIds = recCount ? targetIds.slice(0, safeLimit) : targetIds;
  return limitTargetIds;
};

export default fetchLnlPayload;
