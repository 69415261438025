import { differenceInDays } from 'date-fns';
import {
  getCookieData,
  findTargetCookie,
  getCookies,
  setCookie,
  validateCookieObject,
} from './cookie-helpers';
import { COOKIE_NAME } from '../constants';

function incrementCookie(): void {
  const cookieList = getCookies();
  const targetCookie = findTargetCookie(COOKIE_NAME, cookieList);
  const currentDatetime = new Date();
  const defaultCookieData = {
    articlesLastAccessed: currentDatetime.toISOString(),
    articlesViewed: 1,
    promoClosed: null,
    hasSignedUp: false,
  };
  // first time visitor
  if (!targetCookie) {
    setCookie(defaultCookieData);
    return;
  }
  // parse cookie
  let cookieData;
  try {
    cookieData = getCookieData(targetCookie);
  } catch (e) {
    setCookie(defaultCookieData);
    return;
  }
  // validate cookie
  const isValid = validateCookieObject(cookieData);
  if (!isValid) {
    setCookie(defaultCookieData);
    return;
  }

  // increment articles_viewed if user last visited within 30 days, otherwise reset to 1
  const articlesLastAccessed = new Date(cookieData.articlesLastAccessed);
  const daysDifference = differenceInDays(
    currentDatetime,
    articlesLastAccessed
  );
  if (daysDifference > 30) {
    cookieData.articlesViewed = 1;
  } else {
    cookieData.articlesViewed += 1;
  }

  // update articles_last_accessed
  cookieData.articlesLastAccessed = currentDatetime.toISOString();

  // set cookie
  setCookie(cookieData);
}

export default incrementCookie;
