import { LNL_BASE_URL, LNL_STATIC_PARAMS } from '../constants';

interface IBuildRecUrlOptions {
  recCount?: number;
}

/**
 * Builds a URL for fetching recommended articles from Local News Lab API.
 *
 * @param {Number} contentId - ID of article to fetch related articles for
 * @param {Object} options - options for fetching
 * @param {Number} options.recCount - number of articles to fetch.
 *
 * @return {String} - URL for fetching recommended articles
 */
const buildLnlUrl = (
  contentId: number,
  { recCount }: IBuildRecUrlOptions = {}
): string => {
  const sourceParam = `&source_entity_id=${contentId}`;
  const limitParam = recCount ? `&size=${recCount}` : '';
  return `${LNL_BASE_URL}/recs?${LNL_STATIC_PARAMS}${limitParam}${sourceParam}`;
};

export default buildLnlUrl;
