import { subDays } from 'date-fns';
import { ICookieObj } from '../types';
import {
  getCookies,
  setCookie,
  findTargetCookie,
  getCookieData,
  getCurrentUTCTime,
  validateCookieObject,
} from './cookie-helpers';
import { COOKIE_NAME } from '../constants';

export function getPromoCookieData(): ICookieObj {
  const cookieList = getCookies();
  const targetCookie = findTargetCookie(COOKIE_NAME, cookieList);
  if (!targetCookie) {
    throw new Error("Couldn't find promo cookie in client's cookies");
  }
  const cookieData: ICookieObj = getCookieData(targetCookie);
  const isValid = validateCookieObject(cookieData);
  if (!isValid) {
    throw new Error('Promo cookie data is malformed');
  }
  return cookieData;
}

/**
 * Updates the value of a cookie with the current UTC datetime.
 *
 * @returns {void}
 */
export function setCookieClosed(): void {
  const cookieData: ICookieObj = getPromoCookieData();
  cookieData.promoClosed = getCurrentUTCTime();
  setCookie(cookieData);
}

/**
 * Updates the value of a cookie to indicate that the user has signed up.
 *
 * @returns {void}
 */
export function setCookieSignedUp(): void {
  const cookieData: ICookieObj = getPromoCookieData();
  cookieData.hasSignedUp = true;
  setCookie(cookieData);
}

/**
 * Determines whether the promo should be shown to the user based on
 * the cookie data.
 *
 * @returns {boolean}
 */
export function shouldShowPromo(): boolean {
  let cookieData: ICookieObj;
  try {
    cookieData = getPromoCookieData();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to execute shouldShowPromo', e);
    return false;
  }
  const { hasSignedUp, promoClosed } = cookieData;

  // if a user has signed up, don't show the promo
  if (hasSignedUp) {
    return false;
  }

  // if the user has closed the promo in the last 7 days, don't show the promo
  const sevenDaysAgo = subDays(new Date(), 7);
  if (promoClosed && new Date(promoClosed) > sevenDaysAgo) {
    return false;
  }

  return true;
}
