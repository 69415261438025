import React from 'react';
import Container from './Container';
import StoryBlockLoader from './StoryBlockLoader';

interface IArticleRecsLoaderProps {
  articles?: number;
}

const ArticleRecsLoader: React.FC<IArticleRecsLoaderProps> = ({
  articles = 1,
}) => {
  return (
    <Container>
      <li className="is-sr-only">
        Loading recommendations for further reading
      </li>
      {Array.from({ length: articles }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <StoryBlockLoader key={i} />
      ))}
    </Container>
  );
};

export default ArticleRecsLoader;
