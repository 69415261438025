import { addDays } from 'date-fns';
import { COOKIE_NAME } from '../constants';
import { ICookieObj } from '../types';

/**
 * Retrieves a list of cookie names and values as an array of strings.
 * Note: Does not return path and expiration date info.
 *
 * Eg. ["cookie_name1=value_1", "cookie_name1=value_2"]
 *
 * @returns {string[]}
 */
export function getCookies(): string[] {
  return document.cookie.split(';').map((cookie) => cookie.trim());
}

/**
 * Retrieves the target cookie's name and value from the list of cookies.
 * eg. "cookie_name1=value_1"
 *
 * @param {string[]} cookieList - List of cookies.
 * @returns {string | undefined} - Target cookie string or undefined if not found.
 */
export function findTargetCookie(
  targetCookie: string,
  cookieList: string[]
): string | undefined {
  return cookieList.find((cookie) => cookie.startsWith(`${targetCookie}=`));
}

/**
 * Retrieves the cookie data from the target cookie string.
 *
 * @param {string} targetCookie - Target cookie string.
 * @returns {Object} - Parsed cookie data object.
 */
export function getCookieData(targetCookie: string): ICookieObj {
  const cookieParts = targetCookie.split('=');
  const cookieVal = cookieParts[1];
  return JSON.parse(cookieVal);
}

/**
 * Retrieves the current UTC datetime as a string.
 *
 * @returns {string} - Current UTC datetime.
 */
export function getCurrentUTCTime(): string {
  return new Date().toISOString();
}

/**
 * Retrieves the expiration date as a UTC string, set to 365 days from the current datetime.
 *
 * @returns {string} - Expiration date in UTC string format.
 */
function getExpirationDate(): string {
  const expirationDate = addDays(new Date(), 365);
  return expirationDate.toUTCString();
}

/**
 * Sets a cookie to the client's browser.
 *
 * @param {ICookieObj} cookieData - Cookie data object.
 * @returns {void}
 */
export function setCookie(cookieData: ICookieObj): void {
  const serializedJson = JSON.stringify(cookieData);
  const expires = getExpirationDate();
  document.cookie = `${COOKIE_NAME}=${serializedJson}; expires=${expires}; path=/;`;
}

/**
 * Validates the given object against the specified structure.
 *
 * @param {ICookieObj} obj - The object to validate.
 * @returns {boolean} - Returns true if the object is valid; otherwise, false.
 */
export function validateCookieObject(obj: ICookieObj): boolean {
  // Check if the object is not null or undefined
  if (!obj || typeof obj !== 'object') {
    return false;
  }

  // Define the expected properties and their data types
  const expectedProperties = [
    'articlesLastAccessed',
    'articlesViewed',
    'promoClosed',
    'hasSignedUp',
  ];

  // Check if all the expected properties are present and have the correct data types
  const isValid = expectedProperties.every((prop: string): boolean => {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return true;
    }
    return false;
  });
  return isValid;
}
