import React, { useEffect, useRef, useMemo, useState } from 'react';

import CaptionAndCredit from './CaptionAndCredit';

import { IThumb } from '../../types';

function getSrc(sources: IThumb[], elementWidth: number): string {
  let src = '';

  const sortedByWidthAsc = sources.sort((firstSource, secondSource) => {
    const firstThumbWidth = firstSource.width;
    const secondThumbWidth = secondSource.width;

    if (firstThumbWidth > secondThumbWidth) return 1;
    if (firstThumbWidth < secondThumbWidth) return -1;
    return 0;
  });

  sortedByWidthAsc.forEach(({ width: thumbWidth, url }) => {
    if (thumbWidth > elementWidth && !src) {
      src = url;
    }
  });

  return src;
}

interface IImageProps {
  description: string;
  sources: IThumb[];
  credit?: string;
  display?: {
    showCaption?: boolean;
  };
}

const Image: React.FC<IImageProps> = ({
  sources,
  credit,
  description,
  display: { showCaption = false } = { showCaption: false },
}) => {
  const imgClasses = ['c-image__img'];

  const [elementWidth, setElementWidth] = useState<number>(0);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const placeholderRef = useRef<HTMLDivElement | null>(null);

  const srcset = useMemo<string>(
    () =>
      sources
        .map(({ url, width: thumbWidth }) => `${url} ${thumbWidth}w`)
        .join(','),
    [sources]
  );
  const src = useMemo<string>(
    () => (elementWidth ? getSrc(sources, elementWidth) : ''),
    [sources, elementWidth]
  );

  function onImageLoad(): void {
    setDidLoad(true);
  }

  useEffect(
    () => {
      setDidLoad(false);

      if (placeholderRef.current) {
        setElementWidth(placeholderRef.current.offsetWidth);
      }
    },
    [sources]
  );

  if (didLoad) {
    imgClasses.push('did-load');
  }

  return (
    <figure className="c-image">
      <div
        ref={placeholderRef}
        className="c-image__placeholder has-bg-gray-light"
      >
        {src && (
          <img
            src={src}
            alt={description}
            sizes={`${elementWidth}px`}
            srcSet={srcset}
            onLoad={onImageLoad}
            className={imgClasses.join(' ')}
          />
        )}
      </div>
      {showCaption && credit && (
        <CaptionAndCredit credit={credit} caption={description} />
      )}
    </figure>
  );
};

export default Image;
