import fetchLnlPayload from './fetchLNLPayload';
import fetchTTPayload from './fetchTTPayload';
import { IApiArticle } from '../../types';

interface IFetchRecsOptions {
  recCount?: number;
}

/**
 * Fetches a list of IDs for recommended articles from Local News Lab API
 * and then fetches payloads for each article from Texas Tribune Articles API.
 *
 * @param {number} contentId - ID of a Tribune article. Articles related to this article
 *  will be fetched.
 * @param {Object} options - options for fetching
 * @param {Number} options.recCount - number of articles to fetch. Max of 10.
 * @returns
 */
async function fetchRecs(
  contentId: number,
  { recCount = 10 }: IFetchRecsOptions = {}
): Promise<IApiArticle[]> {
  // Because Texas Tribune articles API returns a max of 10 articles in a single response
  // we prohibit requesting more than 10 article recommendations.
  if (recCount > 10) {
    const errorMsg = `Requested ${recCount} articles, but API allows a max of 10.`;
    throw new Error(errorMsg);
  }
  const recommendedArticleIds = await fetchLnlPayload(contentId, recCount);
  const articleMetadata = await fetchTTPayload(recommendedArticleIds);
  return articleMetadata;
}

export default fetchRecs;
