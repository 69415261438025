import { IState } from './types';

export const INIT_STATE: IState = {
  isPending: false,
  success: false,
  errorMsg: undefined,
  successMsg: undefined,
};

export const COOKIE_NAME = 'tt_promo';
export const GA_LABEL = 'story-page';
export const THE_BRIEF = {
  id: '20c921ee0a',
  name: 'The Brief',
};
export const THE_BRIEF_LOGO_SRC = '/static/images/promo-brief-logo.svg';
export const MEMBERSHIP_DRIVE = {
  name: 'Membership Drive',
};
export const MEMBERSHIP_DRIVE_LOGO_SRC = '/static/images/promo-gt-logo.svg';
export const PROMO_NAMES = {
  msgBar: 'message-bar',
  modal: 'modal',
  donationModal: 'donation-modal',
};
