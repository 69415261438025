import { ERR_MSG_TT_FETCH_FAILED } from '../constants';
import checkDuplicates from './checkDuplicates';
import { ARTICLE_BASE, ARTICLE_FIELDS } from '../../constants';
import { IApiArticle } from '../../types';

/**
 * Fetches article data from Texas Tribune Articles API when provided
 * a list of article IDs. Returns a max of 10 items.
 *
 * @param {Array} targetIds - array of unique article IDs
 * @returns {Promise<Array>} - promise with array of article payloads
 */
const fetchTTPayload = async (targetIds: number[]): Promise<IApiArticle[]> => {
  if (checkDuplicates(targetIds)) {
    throw new Error('targetIds contains duplicate IDs');
  }
  const targetIdsParam = targetIds.map((item) => `id=${item}`).join('&');
  // Note: We make a request to the production API, rather than the local API
  // This is allow the caller to provide a list of IDs that target articles
  // that have been recently added to the production site. Long term, we should
  // we should consider only using the local API.
  const url = `https://www.texastribune.org${ARTICLE_BASE}?${targetIdsParam}&fields=${ARTICLE_FIELDS}`;
  const res = await fetch(url);
  if (!res.ok) {
    const msg = `${ERR_MSG_TT_FETCH_FAILED}: ${res.status}`;
    throw new Error(msg);
  }
  const payload = await res.json();
  if (!payload.results || !payload.results.length) {
    const msg = `${ERR_MSG_TT_FETCH_FAILED}: no results returned`;
    throw new Error(msg);
  }
  /**
   * Note: If the Articles API doesn't recognize an article ID, it will
   * default to returning a paginated list of all articles. We throw an
   * error as this behavior may not be what the caller expected.
   */
  if (payload.results.length !== targetIds.length) {
    const msg = `${ERR_MSG_TT_FETCH_FAILED}: unexpected number of results returned`;
    throw new Error(msg);
  }
  return payload.results;
};

export default fetchTTPayload;
